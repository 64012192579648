body,
#root {
  min-height: 100%;
}

legend span {
  z-index: initial !important;
}

.menu-position-top.menu-w
  ul.main-menu
  > li.selected
  .sub-menu-w
  .sub-menu-i
  ul.sub-menu {
  overflow-x: hidden;
}

ul.main-menu > li .sub-menu-w {
  transition: none !important;
}

.auth-container {
  min-height: 100vh;

  & .auth-subcontainer {
    min-height: 100vh;
    padding: 5%;

    & .logo {
      max-width: 400px;
      margin-bottom: 20px;
    }

    &.left {
      text-align: center;

      & h2 {
        text-transform: uppercase;
        font-size: 20px;
        color: #c03239;
      }

      & p {
        text-align: left;
        max-width: 400px;
        margin: 0 auto;
        background: #eaeef5;
        padding: 10px 20px;
        border-radius: 4px;
        color: #595959;
      }
    }

    &.right {
      background: #ffffff;

      & .form-check-inline {
        margin-left: 10px;
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Add styles here you want to override
.upload-dropzone {
  height: 150px;
  border: 1px dashed #dddddd;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #e9e9e9;
    border: 1px solid $top-bar-bright-bg;
  }

  &.hidden {
    display: none;
  }

  &.has-preview {
    border-top: 1px solid #f4f4f4;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin: 0 auto;
    text-align: center;
  }
}

.form-upload {
  margin-bottom: 10px;

  &.single .preview {
    position: absolute;
    height: 15vh;
    margin: 0 auto;
    cursor: pointer;
    border: 1px dashed #dddddd;

    &:hover {
      opacity: 0.8;
      border: 1px solid $top-bar-bright-bg;
    }
  }

  &.multiple .preview-container {
    border: 1px dashed #dfdfdf;
    background: #f9f9f9;
    border-bottom: none;
    padding: 2px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
  }

  & .preview {
    height: 100px;
    transition: transform 0.2s ease-in-out;
  }

  & .preview:hover {
    transform: scale(0.9, 0.9);
  }

  &.has-error .upload-dropzone {
    border: 2px solid $danger;
  }

  & .error-message {
    color: $danger;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0 10px;
    text-align: center;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: -12px;
}

.form-error {
  color: #fff;
  background-color: $danger;
  padding: 6px 25px;
  border-radius: 4px;
}

.actions-right {
  & .delete-confirm {
    margin: 0 10px;
  }
}

.input-search-w input {
  border: none;
}

.expanded-img {
  position: fixed;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  z-index: 999;
  border: 2px solid #293145;
  box-shadow: 1px 2px 2px #00000044;
}

.expanded-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000066;
  z-index: 998;
  left: 0;
  top: 0;
}

.fares {
  & table {
    & thead th,
    & tbody th {
      background: #2465e2;
      color: #fff;
      font-weight: bold;
      user-select: none;
      cursor: pointer;

      &:hover {
        background: #293146;
        color: #ffb100;
        border-color: #ffb100;
      }
    }

    & thead th {
      text-align: center;
      position: sticky;
      top: 0px;
    }

    & tbody th {
      text-align: right;
    }

    & tbody td.fare {
      cursor: pointer;

      &:hover {
        border: 2px solid #293145;
      }

      &.selected {
        border: 3px solid #000000;
      }
    }
  }

  & .actions {
    position: sticky;
    top: 40px;
    background: #293145;
    color: #fff;
    height: 41px;
    padding: 10px;
    text-align: right;
    font-weight: bold;

    & .btn {
      margin-left: 10px;
    }
  }
}

.ae-main-menu {
  & .header {
    background: #293145;

    & a span {
      color: #ffffff !important;
      font-weight: bold !important;
    }
  }

  & .loading {
    text-align: center;
  }

  & li.active {
    background: #2465e2;

    & a span,
    a i {
      color: #ffffff;
    }
  }
}

.balance-table {
  margin-top: 1px;
}

.balance-table table td {
  text-align: center;
}

.balance-table table th {
  width: 100%;
  text-align: center;
}

.balance-table table th a {
  color: #ffffff;
}

.balance-table table thead th {
  background: #2465e2;
  color: #fff;
  font-weight: bold;
}

//////////////////////////////////////////

// GRID Layout

///////////////////////////////////////////////

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 5vw);
  grid-gap: 1.5rem;
  padding: 0 2rem;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__items--1{
  grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 1;
	grid-row-end: 4;
	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	align-items: center;  
	align-content: center;
	justify-content: center; 
}

.gallery__item--2 {
  grid-column-start: 3;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 7;

  /** Alternative Syntax **/
  /* grid-column: 3 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__items--2 {
  grid-column-start: 4;
	grid-column-end: 9;
	grid-row-start: 1;
	grid-row-end: 7;
	background-color: #ffffff;
}

.gallery__item--3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}

.gallery__items--3 {
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 4;
	grid-row-end: 7;
	background-color: #ffffff; 
	display: flex;
	flex-direction: column;
	align-items: center;  
	align-content: center; 
	justify-content: center; 
	text-align: center;
  } 

.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 7;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 4;  */
  /* grid-row: 3 / span 3; */
}

input.register-input {
	width: 400px;
	height: 38px;
	padding-left: 15px;
	padding-right: 4%!important; 
	color: #555!important;
	border: 1px solid #cecece!important;
	background-color: #fff!important;
	font-size: 16px!important;
	border-radius: 5px 5px 5px 5px!important; 
  position: absolute;
  inset: 10px 35% 0;
  z-index: 1000;
}

.textBox{
  border: rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style: solid;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
  width: 230px;
  margin-top: 5px;
}

.estimate {
	max-height: 0px;
	overflow: hidden;
	display: flex;
	justify-content: space-evenly;
	margin-top: 2px;
	box-shadow: 0 20px 42px rgba(0, 0, 0, 0.25);
	background-color: #000000;
	border-radius: 8px 8px;
	transition: max-height 400ms ease-out;
}

.estimate.show {
	max-height: 100px;
	height: auto;
	padding: 10px 10px 2px 10px;
}

.estimate-box h4 {
	font-size: 14px;
	margin: 0;
	padding: 0;
	color: #afccff;
	text-transform: uppercase;
}

.estimate-box p {
	font-weight: bold;
	color: #ffffff;
}

.estimate .hero p {
	font-size: 40px;
	margin-top: 9px;
	color: #7fff00;
}

.hovering:hover{
  background-color: #DDDDDD; 
}

.btns {
  background-color: DodgerBlue;
  border: none;
  border-radius: 8px;
  margin-left: 3px;
  color: white;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  height: "90%";
}

.btns:hover {
  background-color: RoyalBlue;
}

.PhoneInputInput{
  display: block;
  width: 100%;
  height: calc(calc(1.5em + 0.75rem) + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #dde2ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputInput:focus{
  color: #495057;
  background-color: #fff;
  border-color: #047bf8;
  outline: 0;
  box-shadow: none;
} 

.activeLink{
  color: #fff;
  background-color: #1b55e2; 
}